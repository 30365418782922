export const quotes = {
  en: [
    { quote: "Life is what happens while you are busy making other plans.", author: "John Lennon" },
    { quote: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
    { quote: "Be yourself; everyone else is already taken.", author: "Oscar Wilde" },
    { quote: "You can never change the past, but you can start today to change the future.", author: "Mary Lou Retton" },
    { quote: "The secret to change is to focus all of your energy not on fighting the old, but on building the new.", author: "Socrates" },
    { quote: "In the end, we will remember not the words of our enemies, but the silence of our friends.", author: "Martin Luther King Jr." },
    { quote: "It is not length of life, but depth of life.", author: "Ralph Waldo Emerson" },
    { quote: "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.", author: "Ralph Waldo Emerson" },
    { quote: "Do not go where the path may lead, go instead where there is no path and leave a trail.", author: "Ralph Waldo Emerson" },
    { quote: "The only impossible journey is the one you never begin.", author: "Tony Robbins" },
    { quote: "Life isn't about finding yourself. Life is about creating yourself.", author: "George Bernard Shaw" },
    { quote: "It does not matter how slowly you go as long as you do not stop.", author: "Confucius" },
    { quote: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
    { quote: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
    { quote: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
    { quote: "You must be the change you wish to see in the world.", author: "Mahatma Gandhi" },
    { quote: "The journey of a thousand miles begins with one step.", author: "Lao Tzu" },
    { quote: "Success is not how high you have climbed, but how you make a positive difference to the world.", author: "Roy T. Bennett" },
    { quote: "What lies behind us and what lies before us are tiny matters compared to what lies within us.", author: "Ralph Waldo Emerson" },
    { quote: "The best way to predict the future is to create it.", author: "Abraham Lincoln" },
    { quote: "Success usually comes to those who are too busy to be looking for it.", author: "Henry David Thoreau" },
    // Добавьте дополнительные цитаты по вашему выбору
  ],
  ru: [
    { quote: "Жизнь — это то, что происходит, пока ты строишь другие планы.", author: "Джон Леннон" },
    { quote: "Единственный способ делать великие дела — любить то, что ты делаешь.", author: "Стив Джобс" },
    { quote: "Будь собой; все остальные роли уже заняты.", author: "Оскар Уайльд" },
    { quote: "Ты не можешь изменить прошлое, но можешь начать с сегодняшнего дня, чтобы изменить будущее.", author: "Мэри Лу Реттон" },
    { quote: "Секрет изменений — это сосредоточить всю свою энергию не на борьбе с прошлым, а на создании нового.", author: "Сократ" },
    { quote: "В конце концов мы будем помнить не слова наших врагов, а молчание наших друзей.", author: "Мартин Лютер Кинг" },
    { quote: "Жизнь измеряется не длиной, а глубиной.", author: "Ральф Уолдо Эмерсон" },
    { quote: "Быть собой в мире, который постоянно пытается сделать тебя кем-то другим, — это величайшее достижение.", author: "Ральф Уолдо Эмерсон" },
    { quote: "Не иди туда, где путь уже проложен. Иди туда, где нет пути, и оставь след.", author: "Ральф Уолдо Эмерсон" },
    { quote: "Единственное невозможное путешествие — это то, которое ты никогда не начинаешь.", author: "Тони Роббинс" },
    { quote: "Жизнь — это не о том, чтобы найти себя. Жизнь — это о том, чтобы создать себя.", author: "Джордж Бернард Шоу" },
    { quote: "Не имеет значения, как медленно ты идешь, главное — не останавливаться.", author: "Конфуций" },
    { quote: "Успех не окончателен, неудача не смертельна: главное — это мужество продолжать.", author: "Уинстон Черчилль" },
    { quote: "Не смотри на часы, делай то, что они делают. Иди дальше.", author: "Сэм Левенсон" },
    { quote: "Будущее принадлежит тем, кто верит в красоту своих мечт.", author: "Элеонор Рузвельт" },
    { quote: "Ты должен быть тем изменением, которое хочешь увидеть в мире.", author: "Махатма Ганди" },
    { quote: "Путь в тысячу миль начинается с одного шага.", author: "Лао Цзы" },
    { quote: "Успех — это не то, как высоко ты поднялся, а то, какой положительный вклад ты внес в мир.", author: "Рой Т. Беннетт" },
    { quote: "То, что за нами и перед нами, — это мелочи по сравнению с тем, что внутри нас.", author: "Ральф Уолдо Эмерсон" },
    { quote: "Лучший способ предсказать будущее — это создать его.", author: "Авраам Линкольн" },
    { quote: "Успех обычно приходит к тем, кто слишком занят, чтобы его искать.", author: "Генри Дэвид Торо" },
    // Добавьте дополнительные цитаты по вашему выбору
  ],
};